import { AbsoluteCenteredLoader } from "@novel/shared/components/CenteredLoader";
import { vendorRouteReplace } from "@vendor-portal/definitions/routeDefinitions";
import { useActiveOrganizationSelector } from "@vendor-portal/redux/reducers/vendorUserOrganizations";
import { useNovelVendorSelector } from "@vendor-portal/redux/reduxHooks";
import { GetServerSidePropsContext } from "next";
import React, { useEffect } from "react";
const IndexPage = (): JSX.Element => {
  const org = useActiveOrganizationSelector();
  const {
    isLoading: gatedExperiencesIsLoading,
    data: gatedExperiences
  } = useNovelVendorSelector(state => state.gatedExperiences);
  const gatedExperiencesLength = gatedExperiences.length;
  useEffect(() => {
    if (org?.canSeeWalletPass) {
      vendorRouteReplace("/wallet-pass");
    } else if (!gatedExperiencesIsLoading && !gatedExperiencesLength) {
      vendorRouteReplace("/setup");
    } else {
      vendorRouteReplace("/gated-experience");
    }
  }, [gatedExperiencesIsLoading, org, gatedExperiencesLength]);
  return <AbsoluteCenteredLoader />;
};

/**
 * Adding this populates ctx.req in _document.tsx
 * See: https://github.com/vercel/next.js/discussions/18235
 */
export const getServerSideProps = async (_ctx: GetServerSidePropsContext) => {
  return {
    props: {}
  };
};
export default IndexPage;